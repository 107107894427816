body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #feb63c;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper {
  width: 100vw;
  max-width: 850px;
  padding: 20px;
}

.swiper-slide {
  border-radius: 10px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.swiper-button-prev,
.swiper-button-next {
  color: white !important;
}

.swiper-pagination-bullet-active {
  background: white !important;
}
